export const factorsData = [
  {
    title: 'Le terrain',
    image: '/images/house2.jpg',
    description: "Un terrain bien aménagé, spacieux et facile d'accès augmente la valeur d'une propriété.",
  },
  {
    title: "L'état de la propriété",
    image: '/images/livingroom2.jpg',
    description: "Une maison bien entretenue au fil des années augmentera la valeur de la propriété.",
  },
  {
    title: "Les taux d'intérêt",
    image: '/images/interest.jpg',
    description: "Lorsque le taux d'intérêt hypothécaire augmente, la demande diminue, et la valeur de votre propriété diminue.",
  },
  {
    title: "L'emplacement",
    image: '/images/montreal-old-port.jpg',
    description: "Si votre propriété est située dans un bon quartier, elle vaudra plus qu'une propriété situé dans un moins bon quartier.",
  },
]

export const factorsDataEng = [
  {
    title: 'The lot',
    image: '/images/house2.jpg',
    description: "A well landscaped, spacious and easily accessible lot increases the value of a property.",
  },
  {
    title: "The property's state",
    image: '/images/livingroom2.jpg',
    description: "A house that is well maintained over the years will increase the value of the property.",
  },
  {
    title: "The interest rates",
    image: '/images/interest.jpg',
    description: "When the mortgage interest rate goes up, demand goes down and the value of your property goes down.",
  },
  {
    title: "The location",
    image: '/images/montreal-old-port.jpg',
    description: "If your property is located in a good neighbourhood, it will be worth more than a property located in a poorer neighbourhood.",
  },
]