import React from 'react'
import styled from 'styled-components'

import { descriptionData, descriptionDataEng } from '../data/descriptionData'
import DescriptionCard from './descriptionCard'

interface Props {
  language: string
}
interface Description {
  title: string
  image: string
  description: string
}

function DescriptionSection({ language }: Props) {
 return (
  <Wrapper>
    {language === 'fr' ? 
    (
    <Title><span>Pourquoi <em>Estimation sans frais</em> ?</span></Title>
      ) : (
    <Title><span>Why <em>Estimation sans frais</em> ?</span></Title>
    )
  
  }
    <CardsWrapper>
      {language === 'fr' ?
      descriptionData.map((description: Description, index: number) => {
        return (
          <DescriptionCard key={`DescriptionCard-${index}`} title={description.title} image={description.image} description={description.description}/>
        )
      }) : 
      descriptionDataEng.map((description: Description, index: number) => {
        return (
          <DescriptionCard key={`DescriptionCard-${index}`} title={description.title} image={description.image} description={description.description}/>
        )
      })}
    </CardsWrapper>
  </Wrapper>
  )
}

export default DescriptionSection

const Wrapper = styled.div`
  margin: 100px auto;
`

const Title = styled.h2`
  color: #000;
  text-align: center;
  
  span{
    padding: 10px;
  }
`

const CardsWrapper = styled.div`
  padding-top: 100px;
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-items: center;
  gap: 20px;

  @media (max-width: 1140px) {
    grid-template-columns: repeat(1, auto);
  }
`
