import React from 'react'
import styled from 'styled-components'

interface Props {
  title: string
  image: string
  description: string
}

function FactorCard(props: Props) {
  const {title, image, description} = props

 return (
  <Wrapper>
    <Image src={image}/>
    <TextWrapper>
      <Title>{title}</Title>
      <p>{description}</p>
    </TextWrapper>
  </Wrapper>
  )
}

export default FactorCard

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 120px auto;
  gap: 10px;
  max-width: 400px;
  min-width: 285px;
  height: 200px;
  background: white;
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  border-radius: 20px;
  overflow: hidden;

  @media (max-width: 385px) {
    height: 250px;
  }
`

const Image = styled.img`
  width: 120px;
  height: 100%;
  object-fit: cover;
`
const TextWrapper = styled.div`
  display: grid;
  gap: 20px;
  padding: 20px 10px;
`

const Title = styled.h3`
  color:  #000;
`

