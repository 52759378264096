import React from 'react'
import styled from 'styled-components'

import FactorCard from './factorCard'

import { factorsData, factorsDataEng } from '../data/factorsData'

interface Props {
  language: string
}
interface Factor {
  title: string
  image: string
  description: string
}

function FactorsSection({ language }: Props) {

 return (
  <Wrapper>
    {language === 'fr' ? 
    <Title><span>Quels facteurs influencent la valeur de ma maison?</span></Title> : <Title><span>What factors affect the value of my home?</span></Title>}
    <CardsWrapper>
      {language === 'fr' ?
      factorsData.map((data: Factor, index: number) => (
        <FactorCard key={`FactorCard-${index}`}image={data.image} title={data.title} description={data.description}/>
      ))
      :
      factorsDataEng.map((data: Factor, index: number) => (
        <FactorCard key={`FactorCard-${index}`}image={data.image} title={data.title} description={data.description}/>
      ))
      }
    </CardsWrapper>
  </Wrapper>
  )
}

export default FactorsSection

const Wrapper = styled.div`
  margin: 100px auto;
`

const Title = styled.h2`
  color:  #000;
  text-align: center;
  
  span{
    padding: 10px;
  }
`

const CardsWrapper = styled.div`
  padding-top: 100px;
  display: grid;
  grid-template-columns: auto auto;
  gap: 40px;
  justify-content: center;

  @media (max-width: 796px) {
    grid-template-columns: auto;
  }
`
