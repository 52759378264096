export const descriptionData = [
  {
    title: 'Outil simple',
    image: '/images/bedroom.jpg',
    description: "Notre outil est simple : lorsque vous êtes prêt à faire votre demande d’estimation, vous n’avez qu’à remplir le court formulaire ci-haut. Un courtier vous recontactera pour vous donner son estimation.",
  },
  {
    title: 'Sans aucun engagement',
    image: '/images/kitchen.jpg',
    description: "La demande d’estimation ne vous engage en rien. En d’autres mots, nous vous fournissons notre estimation de votre propriété : en remplissant le formulaire, vous ne vous engagez aucunement à vendre votre propriété.",
  },
  {
    title: 'Gratuit',
    image: '/images/livingroom.jpg',
    description: "Vous savez ce qui est encore mieux ? Ce service est totalement gratuit ! Oui, oui, gratuit, comme dans « Vous ne payez pas un sou » ! Alors, qu’attendez-vous pour découvrir la valeur marchande de votre propriété ?",
  },
]

export const descriptionDataEng = [
    {
    title: 'Simple tool',
    image: '/images/bedroom.jpg',
    description: "Our tool is simple: when you are ready to request an estimate, simply fill out the short form above. A broker will contact you to give you an estimate.",
  },
  {
    title: 'Without any commitment',
    image: '/images/kitchen.jpg',
    description: "The request for an estimate does not commit you in any way. In other words, we provide you with our estimate of your property: by filling out the form, you are not committing yourself to sell your property.",
  },
  {
    title: 'Free of charge',
    image: '/images/livingroom.jpg',
    description: "You know what's even better? This service is totally free! Yes, yes, free, as in 'You don't pay a penny'! So, what are you waiting for to find out the market value of your property?",
  },
]