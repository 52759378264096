import React from "react"

import useLanguage, { LanguageProvider } from "../states/context/LanguageContext"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DescriptionSection from "../components/descriptionSection"
import FactorsSection from "../components/factorsSection"

const IndexPage = () => {
  const { language } = useLanguage()

  return (
  <Layout>
    <SEO title={language === 'fr' ? "Accueil" : 'Home'} />
    <DescriptionSection language={language}/>
    <FactorsSection language={language}/>
  </Layout>)
}

const IndexPageHOC = () => {
  return (
  <LanguageProvider>
    <IndexPage />
  </LanguageProvider>

  )
}
export default IndexPageHOC
