import React from 'react'
import styled from 'styled-components'

interface Props {
  title: string
  image: string
  description: string
}

interface StyledProps {
  background: string
}

function DescriptionCard(props: Props) {
  const { title, image, description } = props

 return (
  <Wrapper background={image}>
    <Title>{title}</Title>
    <Description>{description}</Description>
  </Wrapper>
  )
}

export default DescriptionCard

const Wrapper = styled.div<StyledProps>`
  height: 300px;
  width: 300px;
  border-radius: 20px;
  background-image: url(${props => props.background});
  background-size: cover;
  object-fit: cover;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  transition: 3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  p {
    display: none;
  }

  &:hover {
    transition: 3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    background: #ffffff;
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    align-items: flex-start;
    text-align: center;
    flex-direction: column;
    h3 {
      color: #000;
    }

    p {
      display: block;
      padding: 10px 0;
    }
  }

  @media (max-width: 405px) {
    height: 250px;
    width: 250px;
  }

  @media (max-width: 372px) {
    height: 200px;
    width: 200px;
  }
`

const Title = styled.h3`
  color: white;
  padding: 0 10px;
  margin: 0 auto;
  text-align: center;
`

const Description = styled.p``
